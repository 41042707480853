<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true" @getList="getList"
		 @clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.order_no" size="small" clearable placeholder="按订单号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input v-model="searchFormData.device_no" size="small" style="width: 150px;" clearable placeholder="按设备编号查询"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select v-model="searchFormData.group_ids" filterable placeholder="请选择场站" size="small" multiple>
					<el-option v-for="(item,k) in groupList" :key="k" :label="item.group_name" :value="item.group_id"></el-option>
				</el-select>
			</el-form-item>

			<!--表格内容-->
			<vxe-table-column slot="table-item" field="order_no" title="订单号" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="device_id" title="消费设备" align="center" min-width="100px">
				<template v-slot="{ row }">
					<div class="vxe-sub" >编号:{{row.device_no}}-{{row.plug_num}}</div>
				</template>
			</vxe-table-column>
			<vxe-table-column slot="table-item" field="group_name" title="场站名称" align="center" min-width="100px" />
			<vxe-table-column slot="table-item" field="pay_price" title="实际付款金额" align="center" min-width="80px" />

			<vxe-table-column slot="table-item" field="electric" title="实际用电量" align="center" width="100px"/>
			<vxe-table-column slot="table-item" field="car_no" title="车牌号" align="center" width="100px"/>
			<vxe-table-column slot="table-item" field="start_time" title="开始时间" align="center" min-width="100px">
			</vxe-table-column>
            <vxe-table-column slot="table-item" field="end_time" title="结束时间" align="center" min-width="100px">

            </vxe-table-column>
			<vxe-table-column slot="table-item" field="orde_status" title="订单状态" align="center" min-width="80px">
				<template v-slot="{ row }">
					<span>{{types.order_status[row.order_status]||'未知状态'}}</span>
				</template>
			</vxe-table-column>
<!--			<vxe-table-column slot="table-item" field="coupon_id" title="优惠券" align="center" width="100px"/>-->
			<vxe-table-column slot="table-item" title="操作" align="center" width="230">
				<template v-slot="{ row }">
				</template>
			</vxe-table-column>
		</Tables>

	</div>
</template>

<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-order-list',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '消费列表',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				groupList: [], //场站列表
				searchFormData: {
					u_type: 10
				},
				types: {
					order_status: {
						10: '进行中',
						20: '已取消',
						30: '已完成'
					}
				},
				// 用户搜索
				sLoading: false,
				sFans: [],
				// 设备搜索
				s2Loading: false,
				sDevice: []
			}
		},
		mounted(){
			const params = {
				token: this.$store.state.user.token,
				page: 1,
				size: 100
			}
			this.$api.Device.GetGroupMinList(params).then((res) => {
				this.groupList = res;
			});
		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				const params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true
				const searchData = this.searchFormData
				// 小区限制
				const res = await this.$api.Ware.GetXOrderList(params, searchData)
				this.tableData = res.data
				this.totalPage = res.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {
					u_type: 10
				}
			},
			// 关闭弹窗
			closeDialog() {
				this.detailDialogShow = false
				this.refundDialogShow = false
			},
			// 列表格式化
			formatType({
				cellValue
			}) {
				if (cellValue == 'server') {
					return '服务器 => 硬件'
				}
				if (cellValue == 'client') {
					return '硬件 => 服务器'
				}
				return '未知'
			}
		}
	}
</script>

<style lang="scss" scoped>
	.row-title {
		font-size: 14px;
		line-height: 20px;
		padding: 4px 0;
		margin-bottom: 18px;

		&:before {
			content: " ";
			width: 5px;
			height: 20px;
			background: #008e4d;
			display: block;
			float: left;
			margin: 0 10px 0 0;
		}
	}
</style>
